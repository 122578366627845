import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { fetchUser } from '../../api/login/login-ultralinq-api';
import { Input, Form, FormButton, FormContainer, Headline, ErrorMessage, GlobalErrorMessage } from './styles';
import React, { useEffect, useState } from 'react';
import Loader from '../Loader/loader';

const usernameFormSchema = yup.object().shape({
    username: yup.string().required('Username is Required'),
});

interface UsernameFormInputs {
    username: string;
    serverError: string;
}

interface UsernameFormProps {
    activeForm: string;
    animationTime: number;
    goToSignIn: () => void;
    usernameUpdated: (name: string) => void;
    authVendorUpdated: (vendor: string) => void;
    authProviderUpdated: (provider: string) => void;
    samlRequiredUpdated: (active: boolean) => void;
}

const UsernameForm: React.FC<UsernameFormProps> = ({
    goToSignIn,
    usernameUpdated,
    authVendorUpdated,
    authProviderUpdated,
    activeForm,
    animationTime,
    samlRequiredUpdated,
}) => {
    const [isActiveForm, setIsActiveForm] = useState(false);

    const {
        register,
        handleSubmit,
        setFocus,
        setError,
        formState: { errors },
    } = useForm<UsernameFormInputs>({
        resolver: yupResolver(usernameFormSchema),
    });

    const setFocusOnUsernameAfterTransition = () => {
        if (activeForm === 'enterUsername') {
            setTimeout(() => {
                setFocus('username');
            }, animationTime);
        }
    };

    const manageActiveFormState = () => {
        if (activeForm === 'enterUsername') {
            setIsActiveForm(true);
        } else {
            setIsActiveForm(false);
        }
    };

    useEffect(setFocusOnUsernameAfterTransition, [isActiveForm]);

    useEffect(manageActiveFormState, [activeForm]);

    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (data: UsernameFormInputs) => {
        setIsLoading(true);
        const response = await fetchUser(data.username);
        setIsLoading(false);
        console.log(response);
        if (response?.type === 'username') {
            setError('username', {
                type: 'string',
                message: response.message,
            });
        } else {
            setError('serverError', {
                type: 'string',
                message: response.message,
            });
        }
        samlRequiredUpdated(response.samlRequired);
        const authVendor = response.auth;
        if (authVendor) {
            authVendorUpdated(authVendor);
        }
        const authProvider = response.provider;
        if (authProvider) {
            authProviderUpdated(authProvider);
        }
        usernameUpdated(data.username);
        goToSignIn();
    };

    return (
        <FormContainer>
            {isLoading && <Loader />}
            <Headline>Welcome Back</Headline>
            <Form onSubmit={handleSubmit(onSubmit)}>
                {errors.serverError?.message && (
                    <GlobalErrorMessage data-testid="login-form-global-error">
                        {errors.serverError?.message}
                    </GlobalErrorMessage>
                )}
                <Input
                    id={'loginUsername'}
                    tabIndex={isActiveForm ? 0 : -1}
                    disabled={isActiveForm ? false : true}
                    {...register('username', { required: true })}
                    placeholder="USERNAME"
                    type="text"
                    className={errors.username && 'input-error'}
                />
                <ErrorMessage data-testid="login-form-username-error">
                    {errors.username && <p>{errors.username.message}</p>}
                </ErrorMessage>
                <FormButton disabled={isActiveForm ? false : true} type="submit" tabIndex={isActiveForm ? 0 : -1}>
                    Continue
                </FormButton>
            </Form>
        </FormContainer>
    );
};

export default UsernameForm;
