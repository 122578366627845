import styled from 'styled-components/macro';

/* MAIN */
export const MainContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //TODO: Move colors to ThemeProvider
    background-color: hsl(0deg 0% 12%);
    color: hsl(0, 0%, 42%);
    border-radius: 0.5rem;
    max-width: 40rem;

    .password-reset-text {
        padding: 1rem;
    }
`;

export const Avatar = styled.div`
    /* Controls the FontAwesomeIcon within */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4.5rem;
    color: white;

    /* This is the avatar container */
    width: 7rem;
    height: 7rem;
    border-radius: 50rem;
    align-self: center;
    background-image: radial-gradient(hsl(203deg 38% 49%), hsl(203deg 38% 49%));

    /* Used to pull avatar upward */
    /* margin-top: -5rem; */
    transform: translateY(-1rem);
`;

export const Headline = styled.h2`
    font-size: 1.75rem;
    color: hsl(0, 0%, 82%);
    text-align: center;
    font-weight: 400;
    margin: 1rem 0rem;
`;

export const SuccessMessage = styled.h2`
    font-size: 21px;
    color: green;
    text-align: center;
    font-weight: 800;
    padding: 20px 0;
    & span {
        display: block;
        font-size: 12px;
        color: hsl(0, 0%, 82%);
    }
`;

export const MfaText = styled.a`
    font-size: 1.75rem;
    color: hsl(203deg 38% 49%);
    text-align: center;
    font-weight: 400;
    margin: 2rem 0rem;
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const FormContainerMFA = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 100%;
    background: #222;
    border-radius: 10px;
    padding-bottom: 25px;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 80%;
`;

export const Input = styled.input`
    height: 2rem;
    border: none;
    background-color: hsl(0deg 0% 21%);
    margin: 0.35rem;
    padding: 0.25rem 1rem;
    color: hsl(203deg 38% 49%);
    border-radius: 5px;
    position: relative;
    &::placeholder {
        color: #4d87ac;
    }
    &:focus {
        outline: 1px solid #4d87ac;
    }
    &.input-error {
        outline: 1px solid #e61102;
    }
`;

export const ErrorMessage = styled.div`
    color: #e61102;
    font-size: 13px;
    padding: 0 0 0 10px;
    text-align: left;
    & p {
        margin: 0px;
        padding: 0 0 20px 0;
    }
`;

export const GlobalErrorMessage = styled.div`
    color: #e61102;
    font-size: 15px;
    padding: 10px 0 10px 0;
    text-align: center;
    & p {
        margin: 0px;
        padding: 0 0 20px 0;
    }
`;

export const MFAGraphicContainer = styled.div``;

export const FormButton = styled.button`
    border: none;
    background-color: hsl(203deg 38% 49%);
    color: white;
    text-transform: uppercase;
    font-weight: 400;
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 0.25rem;
    align-self: center;

    &:active {
        background-color: hsl(203deg 38% 39%);
    }
`;

export const FormLink = styled.a`
    cursor: pointer;
    color: hsl(203deg 38% 49%);
`;

export const FieldLink = styled.a`
    cursor: pointer;
    color: hsl(203deg 38% 49%);
    text-align: right;
`;

export const HorizontalLine = styled.hr`
    width: 90%;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    border: 1px solid #ccc;
`;

// Slides
export const SlideContainer = styled.div`
    display: grid;
    width: 100%;
    text-align: center;
    overflow: hidden;
`;

interface SlideProps {
    position: string;
    animationTime: number;
}

export const Slide = styled.div<SlideProps>`
    grid-column-start: 1;
    grid-row-start: 1;
    transition: opacity ${(props) => (props.animationTime ? props.animationTime.toString() + 'ms' : '500ms')}
        ease-in-out;
    transform: translateX(${(props) => (props.position ? props.position : '0') + '000px'});
`;
