import axios, { AxiosError } from 'axios';

export interface LoginData {
    username: string;
    password: string;
}

export interface ForgotPasswordData {
    username: string;
    email: string;
}

export async function saml_login(provider: string, username: string): Promise<ErrorByCode> {
    return await new Promise(async (resolve): Promise<ErrorByCode> => {
        const params = new URLSearchParams();
        params.append('provider', provider);
        params.append('username', username);
        return await axios({
            method: 'post',
            url: '/1/auth/sso_auth/',
            headers: {
                accept: '*/*',
                'content-type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
            },
            data: params,
        })
            .then((response): any => {
                const { redirect }: { redirect: string } = response && response.data ? response.data : { redirect: '' };

                if (redirect as string) {
                    window.location.replace(redirect);
                } else {
                    resolve(getErrorByCode('4061'));
                }
            })
            .catch((reason: AxiosError): any => {
                if (reason.response?.status && reason.response.status >= 300) {
                    resolve(getErrorByCode(reason.response.status.toString()));
                }
            });
    });
}

export async function fetchUser(data: string): Promise<any> {
    return await new Promise(async (resolve): Promise<any> => {
        const params = new URLSearchParams();
        params.append('username', data);

        return await axios
            .get('/1/auth/preauth', { params })
            .then((value): any => {
                if (value && value.data) {
                    resolve(value.data);
                }
            })
            .catch((reason: AxiosError): any => {
                if (reason.response?.status && reason.response.status >= 300) {
                    resolve(getErrorByCode(reason.response.status.toString()));
                }
            });
    });
}

export default async function login(data: LoginData, username: string): Promise<ErrorByCode> {
    return await new Promise(async (resolve): Promise<ErrorByCode> => {
        const params = new URLSearchParams();
        params.append('username', username);
        params.append('password', data.password);

        return await axios({
            method: 'post',
            url: '/1/auth/api_login/',
            headers: {
                accept: '*/*',
                'content-type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
            },
            data: params,
        })
            .then((response): any => {
                const { success, status_code }: { success: string | undefined; status_code: string } =
                    response && response.data ? response.data : { success: '', status_code: '' };
                if (success) {
                    if (response.data.a) {
                        window.sessionStorage.setItem('a', response.data.a);
                    }
                    if (response.data.auth_token) {
                        window.sessionStorage.setItem('authToken', response.data.auth_token);
                    }
                    window.location.replace('/?success=true');
                } else if (response.data.challenge_token) {
                    resolve(getErrorByCode('4024', { challenge: response.data.challenge_token }));
                } else if (status_code !== '') {
                    resolve(getErrorByCode(status_code));
                } else {
                    resolve(getErrorByCode('4061'));
                }
            })
            .catch((reason: AxiosError): any => {
                if (reason.response?.status && reason.response.status >= 300) {
                    resolve(getErrorByCode(reason.response.status.toString()));
                }
            });
    });
}

export async function forgotPassword(data: ForgotPasswordData): Promise<ErrorByCode> {
    return await new Promise(async (resolve): Promise<ErrorByCode> => {
        const params = new URLSearchParams();
        params.append('username', data.username);
        params.append('email', data.email);

        return await axios({
            method: 'post',
            url: '/1/auth/api_forgot_password/',
            headers: {
                accept: '*/*',
                'content-type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
            },
            data: params,
        })
            .then((response): any => {
                const { success, status_code }: { success: string | undefined; status_code: string } =
                    response && response.data ? response.data : { success: '', status_code: '' };

                if (success) {
                    window.location.replace('/');
                } else if (status_code !== '') {
                    console.log(status_code);
                    resolve(getErrorByCode(status_code));
                } else {
                    resolve(getErrorByCode('4061'));
                }
            })
            .catch((reason: AxiosError): any => {
                if (reason.response?.status && reason.response.status >= 300) {
                    resolve(getErrorByCode(reason.response.status.toString()));
                }
            });
    });
}

type ErrorByCode = {
    type: 'email' | 'success' | 'username' | 'global' | 'mfa_challenge';
    message: string;
    data?: any;
};

function getErrorByCode(status_code: string, data?: any): ErrorByCode {
    let result = ultralinqApiErrors.hasOwnProperty(status_code)
        ? ultralinqApiErrors[status_code]
        : ultralinqApiErrors['default'];
    if (data) {
        result.data = data;
    }
    return result;
}

export function getAllUltralinqApiErrorCodes(): string[] {
    return [...Array.from(Object.keys(ultralinqApiErrors))];
}

const ultralinqApiErrors: { [key: string]: ErrorByCode } = {
    500: {
        type: 'global',
        message: 'Login server communications failure, please try again later.',
    },
    4001: {
        type: 'global',
        message: 'The company account is either locked or inactive.',
    },
    4002: {
        type: 'global',
        message: 'Username or password incorrect.',
    },
    4003: {
        type: 'global',
        message: 'Login failed too many times. Please reset password via the "Forgot Password" link.',
    },
    4010: {
        type: 'username',
        message: 'User account activation pending.',
    },
    4012: {
        type: 'username',
        message: 'User account is not active.',
    },
    4020: {
        type: 'username',
        message: 'No login found for that user.',
    },
    4021: {
        type: 'global',
        message: 'Login failed too many times. Please reset password via the "Forgot Password" link.',
    },
    4022: {
        type: 'global',
        message: 'Username or password incorrect.',
    },
    4023: {
        type: 'global',
        message: 'Failed to connect to login server.',
    },
    4041: {
        type: 'username',
        message: 'No user found.',
    },
    4042: {
        type: 'email',
        message: 'Invalid email',
    },
    4043: {
        type: 'email',
        message: 'Email does not match records.',
    },
    4024: {
        type: 'mfa_challenge',
        message: 'Mfa authentication required.',
    },
    4025: {
        type: 'global',
        message: 'Your administrator has chosen to use SSO for authentication. Please use the SAML 2.0 Login button.',
    },
    4061: {
        type: 'global',
        message: 'Unable to complete request',
    },
    default: {
        type: 'global',
        message: 'An unknown error has occurred.',
    },
};
