import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import LoginPage from './layouts/LoginPage';
import MfaSignUpPage from './layouts/MfaSignUpPage';
import MfaRemovePage from './layouts/MfaRemovePage';

function startBrowserApiMockServer() {
    if (process.env.NODE_ENV === 'development') {
        const { worker } = require('./api/mocks/browser');
        worker.start();
    }
}

function renderComponentToElement(Component: any, id: string) {
    const element = document.getElementById(id);
    if (!element) return;

    ReactDOM.render(
        <React.StrictMode>
            <Component />
        </React.StrictMode>,
        element,
    );
}

// Entry point
function connectToUltralinq() {
    startBrowserApiMockServer();

    const mfaSignUp = () => {
        return <MfaSignUpPage />;
    };
    const mfaRemove = () => {
        return <MfaRemovePage />;
    };

    renderComponentToElement(LoginPage, 'login-page_root');
    renderComponentToElement(mfaSignUp, 'mfa-signup');
    renderComponentToElement(mfaRemove, 'mfa-remove');
}

window.addEventListener('DOMContentLoaded', connectToUltralinq);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
