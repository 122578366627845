import { ArticleContainer, ArticleHead, ArticleTitle, ArticleDate, ArticleContent } from './styles';

//TODO: This will need to be handled at a higher level for consistency
const formatDate = (dateFromServer = new Date(Date.now())) => {
    const date = new Date(dateFromServer);
    const dateOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
    };

    return date.toLocaleDateString(undefined, dateOptions);
};

export interface NewsFeedArticleProps {
    // title, date, content
    title: string;
    date: Date;
    content: string;
}

const NewsFeedArticle: React.FC<NewsFeedArticleProps> = ({ title, date, content }) => {
    return (
        <ArticleContainer>
            <ArticleHead>
                <ArticleTitle>{title}</ArticleTitle>
                <ArticleDate>{formatDate(date)}</ArticleDate>
            </ArticleHead>
            <ArticleContent>{content}</ArticleContent>
        </ArticleContainer>
    );
};

export default NewsFeedArticle;
