import { SuccessMessage, Form, FormButton, FormContainerMFA, FormLink, Headline, Input } from './styles';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { submitMfaRemove } from '../../api/login/login-beacon-api';

interface MfaRemoveProps {
    activeForm: string;
    goToSignIn?: () => void;
}

interface MfaFormInputs {
    mfa_device_code?: string;
}

const mfaRemoveSchema = yup.object().shape({
    mfa_device_code: yup.string().required('Please enter the unique code from your authenticator device.'),
});

const MfaRemove: React.FC<MfaRemoveProps> = ({ activeForm, goToSignIn }) => {
    const [isActiveForm, setIsActiveForm] = useState(false);
    const [mfaSuccess, setMfaSuccess] = useState('');
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<MfaFormInputs>({
        resolver: yupResolver(mfaRemoveSchema),
    });

    const manageActiveFormState = () => {
        if (activeForm === 'mfaRemove') {
            setIsActiveForm(true);
        } else {
            setIsActiveForm(false);
        }
    };

    const onSubmit = async (data: MfaFormInputs) => {
        if (data.mfa_device_code) {
            const response = await submitMfaRemove(data.mfa_device_code);
            if (response?.status === 'SUCCESS') {
                console.log('MFA Device removed.');
                setMfaSuccess('true');
                setTimeout(function () {
                    if (goToSignIn) {
                        goToSignIn();
                    }
                }, 5000);
            } else {
                setError('mfa_device_code', {
                    type: 'string',
                    message: 'Unable to verify device code, please try again.',
                });
            }
        } else {
            setError('mfa_device_code', {
                type: 'string',
                message: 'Please enter code from your MFA device',
            });
        }
    };

    useEffect(manageActiveFormState, [activeForm]);
    return (
        <FormContainerMFA>
            {mfaSuccess && (
                <SuccessMessage>
                    MFA Disabled Successfully! <span>Redirecting to preferences...</span>
                </SuccessMessage>
            )}
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Headline>Disable MFA</Headline>
                <Input
                    tabIndex={isActiveForm ? 0 : -1}
                    id={'device-code'}
                    {...register('mfa_device_code', { required: true })}
                    placeholder="MFA DEVICE CODE"
                    className={errors.mfa_device_code && 'input-error'}
                ></Input>
                <FormButton type="submit" tabIndex={isActiveForm ? 0 : -1}>
                    Disable MFA Device
                </FormButton>
                <FormLink tabIndex={isActiveForm ? 0 : -1} onClick={goToSignIn}>
                    Back to Manage MFA
                </FormLink>
            </Form>
        </FormContainerMFA>
    );
};

export default MfaRemove;
