import React from 'react';
import logo from './../assets/UltraLinQ.png';
import styled from 'styled-components/macro';
import Login from '../components/Login/Login';
import NewsFeed from '../components/NewsFeed/NewsFeed';

const Page = styled.div`
    display: grid;
    width: 100vw;
    min-height: 100vh;
    grid-template-columns: 1fr minmax(0, 1000px) 1fr;
    background-color: hsl(0deg 0% 9%);
`;

const Main = styled.main`
    font-family: 'Open Sans', sans-serif;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr min-content 2fr;
    grid-gap: 3rem;
    grid-template-areas:
        'logo contact'
        'login newsFeed'
        'footer footer';

    color: white;
    padding: 3rem;

    & h3 {
        font-weight: 400;
        margin: 0;
    }

    & h6 {
        font-weight: 300;
        margin: 0;
    }

    & a {
        color: hsl(203deg 38% 49%);
    }

    & > .logo-area {
        grid-area: logo;
        width: 100%;
        margin-bottom: 1.5rem;
        align-self: center;

        & .logo {
            width: 22rem;
            /* Center Logo based on left-alignment because it has empty space */
            position: relative;
            margin-left: 50%;
            transform: translateX(-50%);
        }
    }

    & > .contact-area {
        grid-area: contact;
        margin-bottom: 3rem;
        display: grid;
        align-content: center;
    }

    & #errorMessage {
        color: #fff !important;
    }

    & > .login-area {
        grid-area: login;
    }
    & > .newsFeed-area {
        grid-area: newsFeed;
    }

    & > footer {
        grid-area: footer;
        text-align: center;
        color: gray;
    }
`;

export interface LoginProps {
    formPosition?: string;
}

const LoginPage: React.FC<LoginProps> = ({ formPosition }) => (
    <Page>
        <div></div>
        <Main>
            <div className="logo-area">
                <img className="logo" src={logo} alt="Logo" />
            </div>
            <div className="contact-area">
                <h3>HELP US IMPROVE</h3>
                <h6>
                    Have Feedback? Contact Client Services by <a href="mailto:MyFeedback@ultralinq.com">EMAIL </a>
                    or for assistance call <a href="tel:1-800-227-2956">1-800-227-2956</a>
                </h6>
            </div>
            <div id="login-root" className="login-area">
                <Login defaultFormPosition={formPosition} />
            </div>
            <div id="newsFeed-root" className="newsFeed-area">
                <NewsFeed />
            </div>
            <footer>
                All ultrasound (US) and Nuclear Medicine (NM) images are stored as 8-bit images, using JPEG and H.264
                lossy compression. No uncompressed images are stored in the UltraLinq Application Server for these
                modalities. This results in a small loss of information on these images. All other modalities are stored
                at their original bit depth using PNG lossless compression. The files in UltraLinq contain confidential
                patient information belonging to the sender that is legally privileged. This information is intended
                only for the use of individuals who have been authorized by the sender and UltraLinq to view this
                information. Individuals who receive this patient information are prohibited from disclosing this
                information to any other party that has not been approved by the sender. If you have arrived at this
                location in error, and/or obtain this information without prior authorization of the sender and/or
                UltraLinq, please notify UltraLinq (1-800-227-2956) and/or the sender immediately, destroy any
                information that has been sent to your computer or which is otherwise in your possession, and keep any
                information that you may have viewed or have become aware of confidential.
            </footer>
        </Main>
        <div></div>
    </Page>
);

export default LoginPage;
