import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Input, Form, FormButton, FormLink, FormContainer, Headline, ErrorMessage, GlobalErrorMessage } from './styles';
import React, { useEffect, useState } from 'react';
import Loader from '../Loader/loader';
import { processAuthResult, submitMfaToken, validateMfaResult } from '../../api/login/login-beacon-api';

interface MfaChallengeForm {
    challenge: string;
    serverError: string;
}

interface MfaChallengeFormProps {
    goToSignIn: () => void;
    activeForm: string;
    animationTime: number;
    username: string;
    session: string;
}

const mfaChallengeSchema = yup.object({
    challenge: yup
        .string()
        .matches(/^\d+$/, { message: 'Please enter a valid MFA Number' })
        .min(6, 'Must be at least 6 characters')
        .max(6, 'Cannot be longer than 6 characters')
        .required('You must enter a MFA number'),
});

const MfaChallengeForm: React.FC<MfaChallengeFormProps> = ({
    goToSignIn,
    activeForm,
    animationTime,
    username,
    session,
}) => {
    const [isActiveForm, setIsActiveForm] = useState(false);

    const {
        register,
        handleSubmit,
        setFocus,
        setError,
        formState: { errors },
    } = useForm<MfaChallengeForm>({
        resolver: yupResolver(mfaChallengeSchema),
    });

    const setFocusOnChallengeAfterTransition = () => {
        if (activeForm === 'mfaChallenge') {
            setTimeout(() => {
                setFocus('challenge');
            }, animationTime);
        }
    };

    const manageActiveFormState = () => {
        if (activeForm === 'mfaChallenge') {
            setIsActiveForm(true);
        } else {
            setIsActiveForm(false);
        }
    };

    useEffect(setFocusOnChallengeAfterTransition, [isActiveForm]);

    useEffect(manageActiveFormState, [activeForm]);

    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (data: MfaChallengeForm) => {
        const response = await submitMfaToken(data.challenge, session, username);
        setIsLoading(true);
        const timer = setTimeout(async () => {
            setIsLoading(false);
            if (response && response.data) {
                let result = processAuthResult(response.data);
                if (!result) {
                    // Something went horribly wrong
                    setError('challenge', {
                        type: 'string',
                        message: 'Failed to receive response from server.',
                    });
                } else {
                    let accessToken = window.sessionStorage.getItem('a') as string;
                    let result = await validateMfaResult(username, accessToken);
                    if (!result.success) {
                        setError('challenge', {
                            type: 'string',
                            message: 'Invalid request or user is disabled.',
                        });
                    }
                    window.location.replace(result.redirect);
                }
            } else {
                setError('challenge', {
                    type: 'string',
                    message: 'Invalid Mfa Code',
                });
            }
        }, 3000);
        return () => clearTimeout(timer);
    };

    return (
        <FormContainer>
            {isLoading && <Loader />}
            <Headline>Enter Verification Code</Headline>
            {errors.serverError?.message && <GlobalErrorMessage>{errors.serverError?.message}</GlobalErrorMessage>}
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Input
                    disabled={isActiveForm ? false : true}
                    tabIndex={isActiveForm ? 0 : -1}
                    {...register('challenge', { required: true })}
                    placeholder="Enter 6-digit Code"
                    type="text"
                    className={errors.challenge && 'input-error'}
                />
                <ErrorMessage>{errors.challenge && <p>{errors.challenge.message}</p>}</ErrorMessage>
                <FormButton disabled={isActiveForm ? false : true} type="submit" tabIndex={isActiveForm ? 0 : -1}>
                    Continue
                </FormButton>
            </Form>
            <FormLink tabIndex={isActiveForm ? 0 : -1} onClick={goToSignIn}>
                Back to Sign In
            </FormLink>
        </FormContainer>
    );
};

export default MfaChallengeForm;
