import { Container, MainTitle, PastUpdatesButton } from './styles';

import NewsFeedArticle from './NewsFeedArticle';
import { fetchArticles, NewsFeedResult } from '../../api/login/login-beacon-api';
import React, { useEffect, useState } from 'react';

const NewsFeed: React.FC = () => {
    const [results, setResults] = useState<NewsFeedResult[]>([{}] as NewsFeedResult[]);

    useEffect(() => {
        const getArticles = async () => {
            const results = await fetchArticles();
            setResults(results);
        };
        getArticles();
    }, []);
    const renderNewsFeed = () => {
        return (
            <>
                {results?.map(({ subject, start, body }, index) => {
                    return <NewsFeedArticle key={index} title={subject} date={start} content={body} />;
                })}
            </>
        );
    };

    return (
        <Container>
            <MainTitle>NEWS & UPDATES</MainTitle>
            {renderNewsFeed()}
            <PastUpdatesButton>Past Updates</PastUpdatesButton>
        </Container>
    );
};

export default NewsFeed;
