import axios from 'axios';

export interface NewsFeedResult {
    id: number;
    subject: string;
    body: string;
    start: Date;
    end: Date;
}

export interface MfaSignupResult {
    accessToken?: string;
    secretKey?: string;
    session?: string;
}

export interface MfaVerifyResult {
    session?: string;
    status?: string;
}

interface MfaSignInResponse {
    success: boolean;
    redirect: string;
}

export function processAuthResult(authResult: any): boolean {
    if (authResult.accessToken) {
        window.sessionStorage.setItem('a', authResult.accessToken as string);
        window.sessionStorage.setItem('r', authResult.refreshToken as string);
        return true;
    }

    return false;
}

export async function fetchArticles(): Promise<NewsFeedResult[]> {
    const response = await axios.get<NewsFeedResult[]>('/1/login/newsfeed');
    if (response.status !== 200) {
        throw new Error('Problem fetching data');
    }
    return response.data;
}

export async function getMfaSignupCode(): Promise<MfaSignupResult> {
    const accessKey = window.sessionStorage.getItem('a');
    const response = await axios.get<MfaSignupResult>('/1/auth/mfa_signup?a=' + accessKey);
    if (response.status !== 200) {
        throw new Error('Problem with Mfa Signup');
    }
    return response.data;
}

export async function validateMfaResult(username: string, access_token: string): Promise<MfaSignInResponse> {
    const params = new URLSearchParams();
    params.append('a', access_token);
    params.append('u', username);
    const response = await axios({
        method: 'post',
        url: '/1/auth/mfa_result/',
        headers: {
            accept: '*/*',
            'content-type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
        },
        data: params,
    });
    if (response.status !== 200) {
        throw new Error('Error while logging in with MFA');
    }
    return response.data;
}

export async function submitMfaSignup(userCode: string): Promise<MfaVerifyResult> {
    const accessKey = window.sessionStorage.getItem('a');
    const params = new URLSearchParams();
    params.append('a', <string>accessKey);
    params.append('code', userCode);
    const response = await axios({
        method: 'post',
        url: '/1/auth/mfa_signup_verify/',
        headers: {
            accept: '*/*',
            'content-type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
        },
        data: params,
    });
    if (response.status !== 200) {
        throw new Error('Problem with Mfa Verify');
    }
    return response.data;
}

export async function submitMfaToken(mfaCode: string, session: string, username: string): Promise<any> {
    const params = new URLSearchParams();
    params.append('mfaCode', mfaCode);
    params.append('session', session);
    params.append('username', username);
    const response = await axios({
        method: 'post',
        url: '/1/auth/mfa_response/',
        headers: {
            accept: '*/*',
            'content-type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
        },
        data: params,
    });
    if (response.status !== 200) {
        throw new Error('Problem with Mfa Response');
    }

    return response.data;
}

export async function submitMfaRemove(mfaCode: string): Promise<any> {
    const accessKey = window.sessionStorage.getItem('a');
    const params = new URLSearchParams();
    params.append('a', <string>accessKey);
    params.append('code', mfaCode);
    const response = await axios({
        method: 'post',
        url: '/1/auth/mfa_remove_verify/',
        headers: {
            accept: '*/*',
            'content-type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
        },
        data: params,
    });
    if (response.status !== 200) {
        throw new Error('Problem with Mfa Response');
    }

    return response.data;
}

export async function getUsername(): Promise<any> {
    const response = await axios({
        method: 'post',
        url: '/1/auth/mfa_get_username/',
        headers: {
            accept: '*/*',
            'content-type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
        },
    });
    if (response.status !== 200) {
        throw new Error('Problem with Username Response');
    }

    return response.data;
}
