import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import login, { saml_login } from '../../api/login/login-ultralinq-api';
import {
    Input,
    Form,
    FormButton,
    FormLink,
    FieldLink,
    FormContainer,
    Headline,
    ErrorMessage,
    GlobalErrorMessage,
    HorizontalLine,
} from './styles';
import React, { useEffect, useState } from 'react';
import Loader from '../Loader/loader';

const loginFormSchema = yup.object().shape({
    password: yup.string().required('Password is Required'),
});

interface LoginFormInputs {
    username: string;
    password: string;
    serverError: string;
}

interface LoginFormProps {
    goToPasswordReset: () => void;
    goBack: () => void;
    goToMfaChallenge: () => void;
    mfaSessionUpdated: (val: string) => void;
    activeForm: string;
    animationTime: number;
    username: string;
    authVendor: string;
    authProvider: string;
    samlRequired: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({
    goToPasswordReset,
    goBack,
    goToMfaChallenge,
    mfaSessionUpdated,
    activeForm,
    animationTime,
    username,
    authVendor,
    authProvider,
    samlRequired,
}) => {
    const [isActiveForm, setIsActiveForm] = useState(false);
    const {
        register,
        handleSubmit,
        setFocus,
        setError,
        formState: { errors },
    } = useForm<LoginFormInputs>({
        resolver: yupResolver(loginFormSchema),
    });

    const setFocusOnPasswordAfterTransition = () => {
        if (activeForm === 'signIn' && !samlRequired) {
            setTimeout(() => {
                setFocus('password');
            }, animationTime);
        } else if (activeForm === 'signIn' && samlRequired) {
            setTimeout(() => {
                document.getElementById('samlLogin')!.focus();
            }, animationTime);
        }
    };
    const resetErrors = () => {
        setError('serverError', {
            type: 'string',
            message: '',
        });
    };

    const manageActiveFormState = () => {
        if (activeForm === 'signIn') {
            setIsActiveForm(true);
        } else {
            setIsActiveForm(false);
        }
    };

    useEffect(resetErrors, [isActiveForm]);
    useEffect(setFocusOnPasswordAfterTransition, [isActiveForm]);

    useEffect(manageActiveFormState, [activeForm]);

    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (data: LoginFormInputs) => {
        setIsLoading(true);
        const response = await login(data, username);
        setIsLoading(false);
        if (response?.type === 'username') {
            setError('username', {
                type: 'string',
                message: response.message,
            });
        } else if (response?.type === 'mfa_challenge') {
            const session = response?.data['challenge'] ?? '';
            if (!session || session === '') {
                setError('serverError', {
                    type: 'string',
                    message: 'Received no challenge code from server.',
                });
            } else {
                mfaSessionUpdated(session);
                goToMfaChallenge();
            }
        } else {
            setError('serverError', {
                type: 'string',
                message: response.message,
            });
        }
    };

    const onSamlClicked = () => {
        setIsLoading(true);
        console.log(authVendor);
        if (authVendor.includes('SSO')) {
            const response = saml_login(authProvider, username);
            console.log(response);
        }
        setIsLoading(false);
    };

    return (
        <FormContainer>
            {isLoading && <Loader />}
            <Headline>Welcome Back</Headline>
            <Form onSubmit={handleSubmit(onSubmit)}>
                {errors.serverError?.message && (
                    <GlobalErrorMessage data-testid="login-form-global-error">
                        {errors.serverError?.message}
                    </GlobalErrorMessage>
                )}
                <Input
                    // this should be entered and validated via UsernameForm. just passing value here.
                    id={'loginUsername'}
                    disabled={true}
                    type="text"
                    value={username}
                />
                <FieldLink onClick={goBack} tabIndex={isActiveForm ? 0 : -1}>
                    Not You?
                </FieldLink>
                <ErrorMessage data-testid="login-form-username-error">
                    {errors.username && <p>{errors.username.message}</p>}
                </ErrorMessage>
                <Input
                    id={'loginPassword'}
                    tabIndex={isActiveForm ? 0 : -1}
                    disabled={!isActiveForm || samlRequired}
                    hidden={samlRequired}
                    {...register('password', { required: true })}
                    placeholder="PASSWORD"
                    type="password"
                    className={errors.password && 'input-error'}
                />
                <ErrorMessage data-testid="login-form-password-error">
                    {errors.password && <p>{errors.password.message}</p>}
                </ErrorMessage>
                <FormButton
                    disabled={!isActiveForm}
                    hidden={samlRequired}
                    type="submit"
                    tabIndex={isActiveForm ? 0 : -1}
                >
                    Login
                </FormButton>
            </Form>
            <FormLink tabIndex={isActiveForm ? 0 : -1} hidden={samlRequired} onClick={goToPasswordReset}>
                Forgot Password?
            </FormLink>
            <HorizontalLine />
            <FormButton id={'samlLogin'} onClick={onSamlClicked} type="button" tabIndex={isActiveForm ? 0 : -1}>
                SAML 2.0 Login
            </FormButton>
        </FormContainer>
    );
};

export default LoginForm;
