import loaderIMG from '../../assets/UL-Icon.png';
import styled, { keyframes } from 'styled-components/macro';

/* SVG Loader */
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoaderContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    #animatedloader {
        animation: ${rotate} infinite 1s ease-in-out;
        z-index: 1000;
    }
    .blackout {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #1f1f1f;
        opacity: 0.7;
    }
`;

const Loader = () => {
    return (
        <LoaderContainer>
            <img id="animatedloader" src={loaderIMG} width={100} height={100} />
            <div className="blackout"></div>
        </LoaderContainer>
    );
};

export default Loader;
