import styled from 'styled-components/macro';

/* MAIN */
export const Container = styled.section`
    display: flex;
    flex-direction: column;
    //TODO: Move colors to ThemeProvider
    background-color: hsl(0, 0%, 9%);
    color: hsl(0, 0%, 42%);
`;

export const MainTitle = styled.h4`
    font-weight: 300;
    letter-spacing: 0.1rem;
    //TODO: Move colors to ThemeProvider
    color: hsl(0, 0%, 82%);
    margin: 0;
    //TODO: Move spacing to ThemeProvider or some Global reference sheet.
    margin-bottom: 1rem;
`;

export const PastUpdatesButton = styled.button`
    background: none;
    border: none;
    margin: 1rem 0 0 0;
    //TODO: Move colors to ThemeProvider
    color: hsl(203deg, 38%, 49%);
`;

/* ARTICLE */
export const ArticleContainer = styled.article``;

export const ArticleHead = styled.div`
    display: flex;
    justify-content: space-between;
    //TODO: Move spacing to ThemeProvider or some Global reference sheet.
    margin-top: 1rem;
    margin-bottom: 0.25rem;
`;

export const ArticleTitle = styled.h5`
    text-transform: uppercase;
    font-size: 0.8em;
    //TODO: Move colors to ThemeProvider
    color: hsl(0, 0%, 82%);
    margin: 0;
`;
export const ArticleDate = styled.h6`
    font-size: 0.8em;
    margin: 0;
`;

export const ArticleContent = styled.p`
    margin: 0;
    font-size: 0.8em;
`;
