import {
    SuccessMessage,
    Form,
    FormButton,
    FormContainerMFA,
    FormLink,
    Headline,
    Input,
    MFAGraphicContainer,
} from './styles';
import QRCode from 'react-qr-code';
import React, { useEffect, useState } from 'react';
import { getMfaSignupCode, submitMfaSignup, getUsername } from '../../api/login/login-beacon-api';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

interface MfaSignUpProps {
    activeForm: string;
    goToSignIn?: () => void;
}

interface MfaFormInputs {
    mfa_device_code?: string;
}

const mfaSignupSchema = yup.object().shape({
    mfa_device_code: yup.string().required('Please enter the unique code from your authenticator device.'),
});

const MfaSignUp: React.FC<MfaSignUpProps> = ({ activeForm, goToSignIn }) => {
    const [isActiveForm, setIsActiveForm] = useState(false);
    const [mfaCode, setMfaCode] = useState('');
    const [mfaSuccess, setMfaSuccess] = useState('');
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<MfaFormInputs>({
        resolver: yupResolver(mfaSignupSchema),
    });

    const manageActiveFormState = () => {
        if (activeForm === 'mfaSignUp') {
            setIsActiveForm(true);
            fetchMfaCode();
        } else {
            setIsActiveForm(false);
        }
    };

    const fetchMfaCode = async () => {
        let username = await getUsername();
        await getMfaSignupCode()
            .then((val) => {
                if (val.secretKey) {
                    let accountname = username.username;
                    let issuer = 'UltraLinq';
                    let label = issuer + ':' + accountname;
                    setMfaCode('otpauth://totp/' + label + '?secret=' + val.secretKey + '&issuer=' + issuer);
                }
            })
            .catch((reason) => {
                console.log('Failed to fetch MFA Signup key: ' + reason);
            })
            .finally(() => {
                console.log('All done with mfa signup');
            });
    };

    const onSubmit = async (data: MfaFormInputs) => {
        if (data.mfa_device_code) {
            const response = await submitMfaSignup(data.mfa_device_code);
            if (response?.status === 'SUCCESS') {
                console.log('MFA Device added.');
                setMfaSuccess('true');
                setTimeout(function () {
                    if (goToSignIn) {
                        goToSignIn();
                    }
                }, 5000);
            } else {
                setError('mfa_device_code', {
                    type: 'string',
                    message: 'Unable to verify device code, please try again.',
                });
            }
        } else {
            setError('mfa_device_code', {
                type: 'string',
                message: 'Please enter code from your MFA device',
            });
        }
    };

    useEffect(manageActiveFormState, [activeForm]);
    return (
        <FormContainerMFA>
            {mfaSuccess && (
                <SuccessMessage>
                    MFA Added Successfully! <span>Redirecting to preferences...</span>
                </SuccessMessage>
            )}
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Headline>MFA Sign Up</Headline>
                <MFAGraphicContainer>
                    <QRCode size={150} value={mfaCode} />
                </MFAGraphicContainer>
                <Input
                    tabIndex={isActiveForm ? 0 : -1}
                    id={'device-code'}
                    {...register('mfa_device_code', { required: true })}
                    placeholder="MFA DEVICE CODE"
                    className={errors.mfa_device_code && 'input-error'}
                ></Input>
                <FormButton type="submit" tabIndex={isActiveForm ? 0 : -1}>
                    Activate MFA Device
                </FormButton>
                <FormLink tabIndex={isActiveForm ? 0 : -1} onClick={goToSignIn}>
                    Back to Manage MFA
                </FormLink>
            </Form>
        </FormContainerMFA>
    );
};

export default MfaSignUp;
