// Styled Components
import { useEffect, useState } from 'react';
import LoginForm from './LoginForm';
import PasswordResetForm from './PasswordResetForm';
import MfaSignUp from './MfaSignUp';
import { MainContainer, Avatar, SlideContainer, Slide } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd } from '@fortawesome/free-solid-svg-icons';
import MfaChallengeForm from './MfaChallengeForm';
import UsernameForm from './UsernameForm';

// Sets the animation time (in milliseconds) for the slides to transition and for any functionality that waits for animations to end.
export const animationTime = 1;

export interface LoginProps {
    defaultFormPosition?: string;
}

const Login: React.FC<LoginProps> = ({ defaultFormPosition }) => {
    const [formPositions, setFormPositions] = useState([
        'enterUsername',
        'signIn',
        'passwordReset',
        'mfaSignUp',
        'mfaChallenge',
    ]);

    const [username, setUsername] = useState('');
    const [authVendor, setAuthVendor] = useState('');
    const [authProvider, setAuthProvider] = useState('');
    const [mfaSession, setMfaSession] = useState('');
    const [samlRequired, setSamlRequired] = useState(false);

    // Some defaults used by storybook
    useEffect(() => {
        if (defaultFormPosition) {
            updateActiveFormOrder(defaultFormPosition);
        }
    }, [defaultFormPosition]);

    /************************************************************************************************
     These functions work to update and read the order of the slides and therefore the content within.
     The index is used as a multiplier, by 1000px, to calculate the position of the form.
     Whichever form is at position "0000px" is un-transformed or the active/visible form.
    ************************************************************************************************/

    const updateActiveFormOrder = (activeForm: string) => {
        const newPositions = formPositions.reduce((forms: string[], form: string): string[] => {
            return form === activeForm ? [form, ...forms] : [...forms, form];
        }, []);

        setFormPositions(newPositions);
    };

    const usernameUpdated = (val: string) => {
        setUsername(val);
    };

    const authVendorUpdated = (val: string) => {
        setAuthVendor(val);
    };

    const authProviderUpdated = (val: string) => {
        setAuthProvider(val);
    };

    const mfaSessionUpdated = (val: string) => {
        setMfaSession(val);
    };

    const samlRequiredUpdated = (val: boolean) => {
        setSamlRequired(val);
    };

    const goToUsernameEntry = () => updateActiveFormOrder('enterUsername');
    const goToSignIn = () => updateActiveFormOrder('signIn');
    const goToPasswordReset = () => updateActiveFormOrder('passwordReset');
    const goToMfaChallenge = () => updateActiveFormOrder('mfaChallenge');

    const getFormPosition = (name: string) => {
        return formPositions.indexOf(name).toString();
    };

    return (
        <MainContainer>
            <Avatar>
                <FontAwesomeIcon icon={faUserMd} />
            </Avatar>
            <SlideContainer data-testid="loginSlideContainer">
                <Slide
                    data-testid="loginSlideUsername"
                    animationTime={animationTime}
                    position={getFormPosition('enterUsername')}
                >
                    <UsernameForm
                        animationTime={animationTime}
                        activeForm={formPositions[0]}
                        goToSignIn={goToSignIn}
                        usernameUpdated={usernameUpdated}
                        authVendorUpdated={authVendorUpdated}
                        authProviderUpdated={authProviderUpdated}
                        samlRequiredUpdated={samlRequiredUpdated}
                    ></UsernameForm>
                </Slide>
                <Slide
                    data-testid="loginSlideLoginForm"
                    animationTime={animationTime}
                    position={getFormPosition('signIn')}
                >
                    <LoginForm
                        animationTime={animationTime}
                        activeForm={formPositions[0]}
                        goBack={goToUsernameEntry}
                        goToPasswordReset={goToPasswordReset}
                        goToMfaChallenge={goToMfaChallenge}
                        mfaSessionUpdated={mfaSessionUpdated}
                        username={username}
                        authVendor={authVendor}
                        authProvider={authProvider}
                        samlRequired={samlRequired}
                    ></LoginForm>
                </Slide>
                <Slide
                    data-testid="loginSlidePasswordResetForm"
                    animationTime={animationTime}
                    position={getFormPosition('passwordReset')}
                >
                    <PasswordResetForm
                        animationTime={animationTime}
                        activeForm={formPositions[0]}
                        goToSignIn={goToSignIn}
                        username={username}
                    ></PasswordResetForm>
                </Slide>
                <Slide
                    data-testid="loginSlideMfaChallengeForm"
                    animationTime={animationTime}
                    position={getFormPosition('mfaChallenge')}
                >
                    <MfaChallengeForm
                        animationTime={animationTime}
                        activeForm={formPositions[0]}
                        goToSignIn={goToSignIn}
                        username={username}
                        session={mfaSession}
                    ></MfaChallengeForm>
                </Slide>
                <Slide animationTime={animationTime} position={getFormPosition('mfaSignUp')}>
                    <MfaSignUp activeForm={formPositions[0]} goToSignIn={goToSignIn}></MfaSignUp>
                </Slide>
            </SlideContainer>
        </MainContainer>
    );
};

export default Login;
