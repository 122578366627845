import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Input, Form, FormButton, FormLink, FormContainer, Headline, ErrorMessage, GlobalErrorMessage } from './styles';
import React, { useEffect, useState } from 'react';
import Loader from '../Loader/loader';
import { forgotPassword } from '../../api/login/login-ultralinq-api';

interface PasswordResetFormInputs {
    username: string;
    email: string;
    serverError: string;
}

interface PasswordResetFormProps {
    goToSignIn: () => void;
    activeForm: string;
    animationTime: number;
    username: string;
}

const passwordResetFormSchema = yup.object().shape({
    username: yup.string().required('Username is Required'),
    email: yup.string().email('Valid Email is Required').required('Email is Required'),
});

const PasswordResetForm: React.FC<PasswordResetFormProps> = ({ goToSignIn, username, activeForm, animationTime }) => {
    const [isActiveForm, setIsActiveForm] = useState(false);

    const {
        register,
        handleSubmit,
        setFocus,
        setError,
        formState: { errors },
    } = useForm<PasswordResetFormInputs>({
        resolver: yupResolver(passwordResetFormSchema),
    });

    const setFocusOnUsernameAfterTransition = () => {
        if (activeForm === 'passwordReset') {
            setTimeout(() => {
                setFocus('username');
            }, animationTime);
        }
    };

    const manageActiveFormState = () => {
        if (activeForm === 'passwordReset') {
            setIsActiveForm(true);
        } else {
            setIsActiveForm(false);
        }
    };

    useEffect(setFocusOnUsernameAfterTransition, [isActiveForm]);

    useEffect(manageActiveFormState, [activeForm]);

    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (data: PasswordResetFormInputs) => {
        setIsLoading(true);
        const response = await forgotPassword(data);
        setIsLoading(false);
        if (response?.type === 'username') {
            setError('username', {
                type: 'string',
                message: response.message,
            });
        } else if (response?.type === 'email') {
            setError('email', {
                type: 'string',
                message: response.message,
            });
        } else {
            setError('serverError', {
                type: 'string',
                message: response.message,
            });
        }
    };

    return (
        <FormContainer>
            {isLoading && <Loader />}
            <Headline>Password Reset</Headline>
            {errors.serverError?.message && <GlobalErrorMessage>{errors.serverError?.message}</GlobalErrorMessage>}
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Input
                    disabled={isActiveForm ? false : true}
                    {...register('username', { required: true })}
                    placeholder="USERNAME"
                    type="text"
                    className={errors.username && 'input-error'}
                    value={username}
                />
                <ErrorMessage>{errors.username && <p>{errors.username.message}</p>}</ErrorMessage>
                <Input
                    disabled={isActiveForm ? false : true}
                    {...register('email', { required: true })}
                    placeholder="EMAIL"
                    type="text"
                    className={errors.email && 'input-error'}
                />
                <ErrorMessage>{errors.email && <p>{errors.email.message}</p>}</ErrorMessage>
                <FormButton disabled={isActiveForm ? false : true} type="submit">
                    Reset Password
                </FormButton>
            </Form>
            <FormLink tabIndex={isActiveForm ? 0 : -1} onClick={goToSignIn}>
                Sign In
            </FormLink>
            <p className="password-reset-text">
                For security purposes, UltraLinq cannot resend your current password. To reset your password, enter your
                email in the field below. UltraLinq will send you instructions to reset your password.
                <br />
                <strong>If you need additional help, please call our Client Services team at</strong>
                <FormLink tabIndex={isActiveForm ? 0 : -1} href="tel:1-800-227-2956">
                    1-800-227-2956
                </FormLink>
            </p>
        </FormContainer>
    );
};

export default PasswordResetForm;
