import React from 'react';
import styled from 'styled-components/macro';
import MfaRemove from '../components/Login/MfaRemove';

const Page = styled.div`
    display: grid;
    width: 100vw;
    min-height: 100vh;
    grid-template-columns: 1fr minmax(0, 1000px) 1fr;
    background-color: hsl(0deg 0% 9%);
`;

const Main = styled.main`
    font-family: 'Open Sans', sans-serif;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr min-content 2fr;
    grid-gap: 3rem;
    grid-template-areas:
        'logo contact'
        'login newsFeed'
        'footer footer';

    color: white;
    padding: 3rem;

    & h3 {
        font-weight: 400;
        margin: 0;
    }

    & h6 {
        font-weight: 300;
        margin: 0;
    }

    & a {
        color: hsl(203deg 38% 49%);
    }

    & > .logo-area {
        grid-area: logo;
        width: 100%;
        margin-bottom: 1.5rem;
        align-self: center;

        & .logo {
            width: 20rem;
            /* Center Logo based on left-alignment because it has empty space */
            position: relative;
            margin-left: 50%;
            transform: translateX(-50%);
        }
    }

    & > .contact-area {
        grid-area: contact;
        margin-bottom: 3rem;
        display: grid;
        align-content: center;
    }

    & #errorMessage {
        color: #fff !important;
    }

    & > .login-area {
        grid-area: login;
        margin: 0 auto;
        width: 400px;
        left: 0;
        right: 0;
        position: absolute;
    }
    & > .newsFeed-area {
        grid-area: newsFeed;
    }

    & > footer {
        grid-area: footer;
        text-align: center;
        color: gray;
    }
`;

const MfaRemovePage: React.FC = () => (
    <Page>
        <div></div>
        <Main>
            <div id="mfa-remove" className="login-area">
                <MfaRemove
                    activeForm="mfaRemove"
                    goToSignIn={() => {
                        window.location.href = '/1/echo/pref_mfa/';
                    }}
                />
            </div>
        </Main>
        <div></div>
    </Page>
);

export default MfaRemovePage;
